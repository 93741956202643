import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Header from '../components/Header'
import Cta from '../components/Cta'

export const BackgroundPageTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <div>
      <Header />

    <section className="">
      {helmet || ''}
    </section>

    <div className="pt-20">
      
    <section className="relative">

      {/* Section background (needs .relative class on parent and next sibling elements) */}

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 pt-40 md:pt-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h2 mb-4 text-4xl font-extrabold leading-tight tracking-tighter" data-aos="zoom-y-out">
              {title}
            </h1>
            <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">{description}</p>
          </div>

          <PostContent content={content} />      


          <h2 className="h2 mb-4 text-2xl font-extrabold leading-tight tracking-tighter mt-20 mb-4">
              Other Backgrounds
            </h2>
          {/* Articles list */}
          <div className="max-w-sm mx-auto md:max-w-none mb-16">

            {/* Articles container */}
            <div className="grid gap-12 md:grid-cols-3 md:col-gap-6 md:row-gap-8 items-start">

              {/* 1st article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out">
                <header>
                  {/* <Link to="/app?designType=svgOverlay" className="block mb-6"> */}
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                      src={require('../../public/img/svg_waves.jpg')} width="352" height="198" alt="An SVG Overlay background, containing a wave SVG on an
                      animated particle background" /> 
                    </figure>
                  {/* </Link> */}
                  <div className="mb-3">

                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                  SVG Wave Background
                    {/* <Link to="/app?designType=svgOverlay" className="hover:underline"></Link> */}
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">Waves on a background of your choice, great for landings!</p>

              </article>

              {/* 2nd article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="150">
                <header>
                  {/* <Link to="/app?designType=triangle" className="block mb-6"> */}
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                      src={require('../../public/img/triangle_background.jpg')} width="352" height="198" alt="Triangle pattern background" />
                    </figure> 
                  {/* </Link>  */}
                  <div className="mb-3">
                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                  Triangle Background
                    {/* <Link to="/app/?designType=triangle" className="hover:underline"></Link> */}
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">A popular option, consisting of a triangle background, displayed as an SVG graphic.</p>
              </article>

              {/* 3rd article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="300">
                <header>
                  {/* <Link to="/app?template=PMh5d0FLzboDcJWwl1CH" className="block mb-6"> */}
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                      src={require('../../public/img/particle_background.jpg')} width="352" height="198" alt="Particles on a gradient background" 
                      />
                    </figure>
                  {/* </Link>  */}
                  <div className="mb-3">

                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                  Animated Particles
                    {/* <Link to="/app?template=PMh5d0FLzboDcJWwl1CH" className="hover:underline"></Link> */}
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">Animated particles on gradient backgrounds, with over 50 templates and counting!</p>

              </article>

            </div>

          </div>

        </div >

      </div >
      <Cta />

      </section >
    </div>
    </div>
  )
}

BackgroundPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BackgroundPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BackgroundPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Background collection">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BackgroundPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BackgroundPage

export const pageQuery = graphql`
  query BackgroundPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`
